import React from "react";

import "./BlakesResume.css";


export default function BlakesResume() {

    return (
        <div className="blakes-resume-box">
            <div className="blakes-resume-heading-box">
                <h1>Blake Davis</h1>

                <div className="blakes-resume-contact-box">
                    <p>blake23847@gmail.com</p>
                    <p>(832) 876-6685</p>
                    <p>blakedavis.work</p>
                </div>
            </div>

            <h3 className="blakes-resume-section-title">Skills</h3>
            <div className="blakes-resume-section-box">
                <div className="blakes-resume-subsection-box">
                    <h4 className="blakes-resume-subsection-border-title">Web development</h4>
                    <ul>
                        <li>HTML5, CSS3</li>
                        <li>JavaScript, TypeScript, ReactJS</li>
                        <li>PHP</li>
                        <li>SEO</li>
                        <li>WordPress (templates, plugins, headless)</li>
                    </ul>
                </div>

                <div className="blakes-resume-subsection-box">
                    <h4 className="blakes-resume-subsection-border-title">iOS & macOS development</h4>
                    <ul>
                        <li>Swift, Objective-C</li>
                        <li>SwiftUI, UIKit</li>
                    </ul>
                </div>

                <div className="blakes-resume-subsection-box">
                    <h4 className="blakes-resume-subsection-border-title">System administration</h4>
                    <ul>
                        <li>Network management</li>
                        <li>Mobile device management</li>
                        <li>Technical support</li>
                    </ul>
                </div>

                <div className="blakes-resume-subsection-box">
                    <h4 className="blakes-resume-subsection-border-title">Miscellaneous</h4>
                    <ul>
                    <li>Java</li>
                    <li>Python</li>
                    <li>SQL</li>
                    </ul>
                </div>
            </div>

            <h3 className="blakes-resume-section-title">Work Experience</h3>
            <div className="blakes-resume-section-box">
                <div className="blakes-resume-subsection-box">
                    <div className="group-box horizontal h-align-space-between">
                        <div className="group-box vertical">
                            <h3>System Administrator & Software Developer</h3>
                            <p>Rodney Lee Brands</p>
                        </div>

                        <div className="blakes-resume-dates-box">
                            <p>Jul 2022 - Dec 2023</p>
                            <p>Austin, TX</p>
                        </div>
                    </div>
                    <ul>
                       <li>Developed and maintained iOS app suite for managing landscaping projects, clients, and employees, enabling real-time communication for more efficient resource management.</li>
                       <li>Rebuilt existing apps in SwiftUI and UIKit to maximize compatibility with available company devices.</li>
                       <li>Built websites to display project portfolios, live streamed events, and online store inventory.</li>
                       <li>Installed and maintained equipment, including servers, workstations, printers, and security systems, across multiple locations.</li> 
                    </ul>
                    
                </div>
                 <div className="blakes-resume-subsection-box">
                    <p>General Manager</p>
                    <p>604 Coffee</p>
                    <p>Jul 2021 - Jul 2022</p>
                    <p>Austin, TX</p>
                    <ul>
                        <li>Built an automated inventory tracking system using Python, Square sale records, and recipe data.</li>
                        <li>Automated and streamlined the inventorying process by developing a program to calculate and adjust inventory based on sales data, reducing cost of unneeded and unused inventory.</li>
                        <li>Maintained budget, inventory, staff, and storefront.</li>
                    </ul>
                 </div>

 <div className="blakes-resume-subsection-box">
        <p>Graphic Designer & Office Manager</p>
        <p>Marjac Creations</p>
        <p>Jul 2015 - Aug 2017</p>
        <p>Houston, TX</p>
        <ul>
            <li>Optimized workflow by creating organizational systems to maximize edit-ability of designs.</li>
            <li>Designed vehicle wraps, banners, awards.</li>
            <li>Drafted production drawings.</li>
        </ul>
        
 </div>
            </div>

            <h3 className="blakes-resume-section-title">Education</h3>
            <div className="blakes-resume-section-box">
                <div className=""></div>
                <p>University of Texas at Austin</p>
                <p>Bachelor of Arts - Linguistics</p>
                <ul>
                    <li>3.96 GPA</li>
                    <li>Graduated with High Honors</li>
                </ul>
            </div>

        </div>
    );
}