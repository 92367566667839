import { useEffect, 
         useCallback, 
         useState, 
         useRef } from "react";

import usePrevious from "../../hooks/UsePrevious.js";
import useEffectAllDependenciesChange from "../../hooks/UseEffectAllDependenciesChange.js";

import Color from "./Color.js";                                                                                                                                                                                                                                                        
import ColorMarker from "./ColorMarker.js";
import ColorPalette from "./ColorPalette.js";
import ColorPicker from "./ColorPicker.js";
import ColorPreview from "./ColorPreview.js";
import ColorPropertySlider from "./ColorPropertySlider.js";
import ColorSwatch from "./ColorSwatch.js";
import ArrowToggle from "./ArrowToggle.js";
import TextToggle from "./TextToggle.js";
import Group from "../Group.js"
import TextButton from "./TextButton";
import ImagesMenu from "./ImagesMenu";

import "./ColorOrganizerStyle.css";



function ColorOrganizer() {

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Environment variables

    const isDevelopment = process.env.NODE_ENV == "development";
    const apiRoot = isDevelopment ? "" : process.env.REACT_APP_API_ROOT;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Defaults / constants

    const defaultPaletteSize = 18;
    const defaultColor = new Color(null, null, null, null, null, null, "000000", 0);


    const [isShowingMenu, setIsShowingMenu] = useState(false);
    const [isShowingAlphaSlider, setIsShowingAlphaSlider] = useState(true);
    const [isShowingHSLSliders, setIsShowingHSLSliders] = useState(true);
    const [isShowingRGBSliders, setIsShowingRGBSliders] = useState(true);


    const [markerCoordinates, setMarkerCoordinates] = useState({x: 0, y: 0});
    const [currentColor, setCurrentColor] = useState(new Color());
    const [colorPalette, setColorPalette] = useState(Array(defaultPaletteSize).fill(defaultColor));
    const [selectedIndex, setSelectedIndex] = useState(0);

    // const [history, setHistory] = useState([currentColor]);
    const pickerRef = useRef();


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Mouse event handling

    const [isMouseDown, setIsMouseDown] = useState(false);

    const handleMouseDown = useCallback((e) => {
        const isMouseDown = true;
        setIsMouseDown(isMouseDown);
    }, []);

    const handleMouseUp = useCallback((e) => {
        const isMouseDown = false;
        setIsMouseDown(isMouseDown);
    }, []);

    const handleMouseMove = useCallback((e) => {
    }, []);


    useEffect(() => {
        window.addEventListener("mousedown", handleMouseDown);
        window.addEventListener("mouseup", handleMouseUp);
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousedown", handleMouseDown);
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [handleMouseDown, handleMouseUp, handleMouseMove]);


    function clickMarker(event) {
        const color = getColorAtCoordinate(event.clientX, event.clientY);
        setCurrentColor(color);
        const mouseCoordinates = getAdjustedMouseCoordinates(event.clientX, event.clientY);
        setMarkerCoordinates(mouseCoordinates);
    }

    // TODO: Change this to be called onMouseMove by a parent of the picker, so dragging out of the picker will still assign the closest color
    function dragMarker(event) {
        if ((event.movementX != 0 || event.movementY != 0) && isMouseDown) {
            let newMarkerCoordinates = getAdjustedMouseCoordinates(event.clientX, event.clientY);
            
            const inBounds = isMouseInBounds(event.clientX, event.clientY); //newMarkerCoordinates.x, newMarkerCoordinates.y);

            console.log("inBounds:", inBounds.x, inBounds.y);
            if (inBounds.x || inBounds.y) {

                if (!inBounds.x) {
                    newMarkerCoordinates.x = markerCoordinates.x;
                }
                if (!inBounds.y) {
                    newMarkerCoordinates.y = markerCoordinates.y;
                }
                const color = getColorAtCoordinate(event.clientX, event.clientY);

                setCurrentColor(color);
                setMarkerCoordinates(newMarkerCoordinates);
            }
        }
    }




    function getAdjustedMouseCoordinates(x, y) {
        let mouseX = x;
        let mouseY = y;
        
        let adjustedMouseX = mouseX; 
        let adjustedMouseY = mouseY + window.scrollY;

        return {x: adjustedMouseX, y: adjustedMouseY};
    }

    function isMouseInBounds(x, y) {
        const pickerBounds = pickerRef.current.getBoundingClientRect();
        let inBoundsHorizontal = x >= pickerBounds.left &&
                                    x <= pickerBounds.right;

        let inBoundsVertical = y >= pickerBounds.top &&
                                y <= pickerBounds.bottom;

        return {x: inBoundsHorizontal, 
                y: inBoundsVertical};
    }


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Get Color at Coordinate

    function getColorAtCoordinate(x, y) {  

        // Refs are always undefined for the first rendering
        if (pickerRef.current !== undefined) {

            const pickerBounds = pickerRef.current.getBoundingClientRect();
            const isInBounds = isMouseInBounds(x, y);
            const hue = currentColor.hue;
            const alpha = currentColor.alpha;

            let saturation = currentColor.saturation;
            let brightness = currentColor.brightness;

            if (isInBounds.x && isInBounds.y) {
                // Update saturation and brightness values
                saturation = (x - pickerBounds.left) / pickerBounds.width;
                brightness = 1 - ((y - pickerBounds.top) / pickerBounds.height);
            }

            return new Color(hue, saturation, brightness, null, null, null, null, alpha);

        } else {
            return defaultColor;
        }
    }



    function getCoordinatesForColor() {
        // Refs are always undefined for the first rendering
        if (pickerRef.current !== undefined) {
            const pickerBounds = pickerRef.current.getBoundingClientRect();

            let adjustedX = currentColor.saturation * pickerBounds.width + pickerBounds.x;
            let adjustedY = -(currentColor.brightness) * pickerBounds.height + pickerBounds.height + pickerBounds.y;
            
            return {x: adjustedX, y: adjustedY};

        } else {
            return {x: 0, y: 0};
        }
    }



    // Called when swatch is clicked
    // Sets the selected swatch index and sets the current color to the swatch's color
    function selectSwatch(event, index) {
        event.preventDefault();
        setSelectedIndex(index);
        let newColor = Object.assign(new Color(), colorPalette[index]);
        setCurrentColor(newColor);
    }

    // Called when "replace" button is clicked
    // Sets the selected swatch to the current color
    function replaceSwatch() {
        console.log(`Set swatch ${selectedIndex} to #${currentColor.hex}${Math.round(currentColor.alpha * 255).toString(16).padStart(2, "0")}.`);
        let currentColorPalette = Object.assign({}, colorPalette);
        currentColorPalette[selectedIndex] = currentColor;
        setColorPalette(currentColorPalette);
    }

    // Add an new swatch to the palette
    function addSwatch() { }

    function deleteSwatch() { }

    function clearPalette() { }

    function selectColor(event) {
        event.preventDefault();
        let newColor = Object.assign(new Color(), currentColor);

        switch (event.target.name) {
            case "hue":
                newColor.hue = Number(event.target.value);
                newColor.setRGB();
                newColor.setHex();
                break;

            case "saturation":
                newColor.saturation = Number(event.target.value);
                newColor.setRGB();
                newColor.setHex();
                break;

            case "brightness":
                newColor.brightness = Number(event.target.value);
                newColor.setRGB();
                newColor.setHex();
                break;

            case "red":
                newColor.red = Number(event.target.value);
                newColor.setHSV();
                newColor.setHex();
                break;

            case "green":
                newColor.green = Number(event.target.value);
                newColor.setHSV();
                newColor.setHex();
                break;

            case "blue":
                newColor.blue = Number(event.target.value);
                newColor.setHSV();
                newColor.setHex();
                break;

            case "alpha":
                newColor.alpha = Number(event.target.value);
                break;

            default:
                console.log("Error: ", event.target.name); 
                break;
        }

        setCurrentColor(newColor);
    }


    return (
        <>
            <div className="color-organizer color-organizer-outer-box group-box vertical">
                <div className="color-organizer color-organizer-inner-box"> 
                    
                    <ColorPicker currentColor={currentColor} markerCoordinates={markerCoordinates} ref={pickerRef} onMove={(event) => {dragMarker(event)}} onClick={(event) => {clickMarker(event)}} />
                    
                    <ColorPalette currentColor={currentColor} colors={colorPalette} selectedIndex={selectedIndex} selectSwatch={selectSwatch} replaceSwatch={replaceSwatch} deleteSwatch={deleteSwatch} addSwatch={addSwatch} clearPalette={clearPalette} />
                    
                    <div className="color-organizer-menu-box group-box vertical">
                        <div className="color-organizer-menu-bar">
                            <div className="color-organizer-menu-bar-leading-box">
                                <div className="color-organizer-menu-bar-leading">
                                    <TextButton label="Assign" onClick={replaceSwatch} />
                                    <TextButton label="Add"     onClick={addSwatch}     />
                                    <TextButton label="Delete"  onClick={deleteSwatch}  />
                                </div>
                            </div>

                            <div className="color-organizer-menu-bar-trailing-box">
                                <div className="color-organizer-menu-bar-trailing">
                                    <TextToggle label="HSL" />
                                    <TextToggle label="RGB" />
                                    <TextToggle label="Hex" />
                                    <TextToggle label="Alpha" />
                                    {/* <div style={{width: "100%"}}></div> */}
                                    <ArrowToggle onChange={(toggleState) => { setIsShowingMenu(toggleState) }}/>
                                </div>
                            </div>

                        </div>

                        <div className="color-organizer-sliders-box">
                            <ColorPropertySlider label={"Hue"}        name="hue"        value={currentColor.hue}        min={0} max={360} step={0.01} onChange={(event) => {selectColor(event)}} isHidden={ !(isShowingMenu && isShowingHSLSliders) } />
                            <ColorPropertySlider label={"Saturation"} name="saturation" value={currentColor.saturation} min={0} max={1}   step={0.01} onChange={(event) => {selectColor(event)}} isHidden={ !(isShowingMenu && isShowingHSLSliders) } />
                            <ColorPropertySlider label={"Brightness"} name="brightness" value={currentColor.brightness} min={0} max={1}   step={0.01} onChange={(event) => {selectColor(event)}} isHidden={ !(isShowingMenu && isShowingHSLSliders) } />
                            <ColorPropertySlider label={"Red"}        name="red"        value={currentColor.red}        min={0} max={255} step={0.01} onChange={(event) => {selectColor(event)}} isHidden={ !(isShowingMenu && isShowingRGBSliders) } />
                            <ColorPropertySlider label={"Green"}      name="green"      value={currentColor.green}      min={0} max={255} step={0.01} onChange={(event) => {selectColor(event)}} isHidden={ !(isShowingMenu && isShowingRGBSliders) } />
                            <ColorPropertySlider label={"Blue"}       name="blue"       value={currentColor.blue}       min={0} max={255} step={0.01} onChange={(event) => {selectColor(event)}} isHidden={ !(isShowingMenu && isShowingRGBSliders) } />
                            <ColorPropertySlider label={"Alpha"}      name="alpha"      value={currentColor.alpha}      min={0} max={1}   step={0.01} onChange={(event) => {selectColor(event)}} isHidden={ !(isShowingMenu && isShowingAlphaSlider)} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ColorOrganizer;
