import React, { useState } from "react";

import "./TextButton.css";


export default function TextButton({onClick=()=>{}, label=""}) {

    return (
        <button className="text-button" 
                onClick={onClick}>
            
            {label}
        
        </button>
    );
}