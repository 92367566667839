import React, { useEffect } from "react";


export default function BoxFiend() {

    return (
        <div class="group-box vertical no-wrap h-align-center" style={{ paddingBottom: "3rem" }}>
            <svg style={{ paddingBottom: "4rem" }} id="boxfiend-logo" viewBox="0 0 2000 276.9">
                <defs>
                    <style>{`
                        #boxfiend-logo {
                            padding-left: 2rem;
                            padding-right: 2rem;
                            width: max-content;
                        }
                        #boxfiend-logo > * {
                            stroke-width: 0px;
                            fill: var(--primary-text-color);
                        }
                        @media (min-width: 600px) {
                            #boxfiend-logo {
                                width: 30rem;
                            }
                        }
                    `}</style>
                </defs>
                <g>
                    <path d="m0,0h61.66v30.64h184.6v246.26H0V0Zm184.6,215.25v-122.94H61.66v122.94h122.94Z"/>
                    <path d="m276.91,30.64h246.26v246.26h-246.26V30.64Zm184.6,184.6v-122.94h-122.94v122.94h122.94Z"/>
                    <path d="m553.81,122.94h92.3v-30.64h-92.3V30.64h246.26v61.66h-92.3v30.64h92.3v153.96h-61.66v-92.3h-122.95v92.3h-61.66V122.94Z"/>
                    <path d="m830.72,30.64h246.26v61.66h-184.6v30.64h184.6v61.66h-184.6v92.3h-61.66V30.64Z"/>
                    <path d="m1107.63,30.64h61.66v61.66h-61.66V30.64Zm0,92.3h61.66v153.96h-61.66V122.94Z"/>
                    <path d="m1199.93,30.64h246.26v153.96h-184.6v30.64h184.6v61.66h-246.26V30.64Zm184.6,92.3v-30.64h-122.94v30.64h122.94Z"/>
                    <path d="m1476.84,30.64h246.26v246.26h-61.66V92.3h-122.94v184.6h-61.66V30.64Z"/>
                    <path d="m1753.74,30.64h184.6V0h61.66v276.9h-246.26V30.64Zm184.6,184.6v-122.94h-122.94v122.94h122.94Z"/>
                </g>
            </svg>
            <svg id="boxfiend-charset" viewBox="0 0 605.05 606.83">
                <defs>
                    <style>{`
                        .boxfiend-character {
                            stroke-width: 0px;
                        }
                        #boxfiend-charset {
                            max-width: 100vw;
                            padding-left: 4rem;
                            padding-right: 4rem;
                            width: 20rem;
                        }
                        #boxfiend-charset-capital > * {
                            fill: var(--site-dark-gray-color);
                        }
                        #boxfiend-charset-lowercase > * {
                            fill: var(--site-dark-gray-color);
                        }
                        #boxfiend-charset-numeric > * {
                            fill: var(--site-dark-gray-color);
                        }
                    `}</style>
                </defs>
                <g>
                    <g id="boxfiend-charset-capital">
                        <path class="boxfiend-character" d="m0,0h54.41v54.41h-13.62v-20.39H13.62v20.39H0V0Zm40.79,20.39v-6.77H13.62v6.77h27.16Z"/>
                        <path class="boxfiend-character" d="m61.18,0h54.41v54.41h-54.41V0Zm40.79,40.79v-6.77h-20.39v-13.62h20.39v-6.77h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m122.36,0h54.41v13.62h-40.79v27.16h40.79v13.62h-54.41V0Z"/>
                        <path class="boxfiend-character" d="m183.55,0h54.41v54.41h-54.41V0Zm40.79,40.79V13.62h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m244.73,0h54.41v13.62h-40.79v6.77h40.79v13.62h-40.79v6.77h40.79v13.62h-54.41V0Z"/>
                        <path class="boxfiend-character" d="m305.91,0h54.41v13.62h-40.79v6.77h40.79v13.62h-40.79v20.39h-13.62V0Z"/>
                        <path class="boxfiend-character" d="m367.09,0h54.41v13.62h-40.79v27.16h27.16v-6.77h-20.39v-13.62h34.02v34.02h-54.41V0Z"/>
                        <path class="boxfiend-character" d="m428.27,0h13.62v20.39h27.16V0h13.62v54.41h-13.62v-20.39h-27.16v20.39h-13.62V0Z"/>
                        <path class="boxfiend-character" d="m489.46,40.79h20.39V13.62h-20.39V0h54.41v13.62h-20.39v27.16h20.39v13.62h-54.41v-13.62Z"/>
                        <path class="boxfiend-character" d="m550.64,20.39h13.62v20.39h27.16V13.62h-40.79V0h54.41v54.41h-54.41V20.39Z"/>
                        <path class="boxfiend-character" d="m0,81.58h13.62v20.39h6.77v-20.39h13.62v20.39h20.39v34.02h-13.62v-20.39H13.62v20.39H0v-54.41Z"/>
                        <path class="boxfiend-character" d="m61.18,81.58h13.62v40.79h40.79v13.62h-54.41v-54.41Z"/>
                        <path class="boxfiend-character" d="m122.36,81.58h54.41v54.41h-13.62v-40.79h-6.77v40.79h-13.62v-40.79h-6.77v40.79h-13.62v-54.41Z"/>
                        <path class="boxfiend-character" d="m183.55,81.58h34.02v40.79h6.77v-40.79h13.62v54.41h-34.02v-40.79h-6.77v40.79h-13.62v-54.41Z"/>
                        <path class="boxfiend-character" d="m244.73,81.58h54.41v54.41h-54.41v-54.41Zm40.79,40.79v-27.16h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m305.91,81.58h54.41v34.02h-40.79v20.39h-13.62v-54.41Zm40.79,20.39v-6.77h-27.16v6.77h27.16Z"/>
                        <path class="boxfiend-character" d="m367.09,81.58h54.41v54.41h-20.39v6.77h-13.62v-6.77h-20.39v-54.41Zm40.79,40.79v-27.16h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m428.27,81.58h54.41v34.02h-20.39v20.39h-13.62v-20.39h-6.77v20.39h-13.62v-54.41Zm40.79,20.39v-6.77h-27.16v6.77h27.16Z"/>
                        <path class="boxfiend-character" d="m489.46,122.36h40.79v-6.77h-40.79v-34.02h54.41v13.62h-40.79v6.77h40.79v34.02h-54.41v-13.62Z"/>
                        <path class="boxfiend-character" d="m550.64,81.58h54.41v13.62h-20.39v40.79h-13.62v-40.79h-20.39v-13.62Z"/>
                        <path class="boxfiend-character" d="m0,163.15h13.62v40.79h27.16v-40.79h13.62v54.41H0v-54.41Z"/>
                        <path class="boxfiend-character" d="m61.18,163.15h13.62v40.79h27.16v-40.79h13.62v54.41h-54.41v-54.41Z"/>
                        <path class="boxfiend-character" d="m122.36,163.15h13.62v40.79h6.77v-40.79h13.62v40.79h6.77v-40.79h13.62v54.41h-54.41v-54.41Z"/>
                        <path class="boxfiend-character" d="m183.55,183.55h20.39v-6.77h-20.39v-13.62h54.41v13.62h-20.39v6.77h20.39v34.02h-13.62v-20.39h-27.16v20.39h-13.62v-34.02Z"/>
                        <path class="boxfiend-character" d="m244.73,203.94h40.79v-6.77h-40.79v-34.02h13.62v20.39h27.16v-20.39h13.62v54.41h-54.41v-13.62Z"/>
                        <path class="boxfiend-character" d="m305.91,183.55h40.79v-6.77h-40.79v-13.62h54.41v34.02h-40.79v6.77h40.79v13.62h-54.41v-34.02Z"/>
                    </g>
                    <g id="boxfiend-charset-lowercase">
                        <path class="boxfiend-character" d="m0,296.61h40.79v-6.77H0v-13.62h54.41v54.41H0v-34.02Zm40.79,20.39v-6.77H13.62v6.77h27.16Z"/>
                        <path class="boxfiend-character" d="m61.18,269.44h13.62v6.77h40.79v54.41h-54.41v-61.18Zm40.79,47.56v-27.16h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m122.36,276.21h54.41v13.62h-40.79v27.16h40.79v13.62h-54.41v-54.41Z"/>
                        <path class="boxfiend-character" d="m183.55,276.21h40.79v-6.77h13.62v61.18h-54.41v-54.41Zm40.79,40.79v-27.16h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m244.73,276.21h54.41v34.02h-40.79v6.77h40.79v13.62h-54.41v-54.41Zm40.79,20.39v-6.77h-27.16v6.77h27.16Z"/>
                        <path class="boxfiend-character" d="m305.91,296.61h20.39v-20.39h34.02v13.62h-20.39v6.77h20.39v13.62h-20.39v20.39h-13.62v-20.39h-20.39v-13.62Z"/>
                        <path class="boxfiend-character" d="m367.09,317h40.79v-6.77h-40.79v-34.02h54.41v54.41h-54.41v-13.62Zm40.79-20.39v-6.77h-27.16v6.77h27.16Z"/>
                        <path class="boxfiend-character" d="m428.27,269.44h13.62v6.77h40.79v54.41h-13.62v-40.79h-27.16v40.79h-13.62v-61.18Z"/>
                        <path class="boxfiend-character" d="m489.46,276.21h13.62v13.62h-13.62v-13.62Zm0,20.39h13.62v34.02h-13.62v-34.02Z"/>
                        <path class="boxfiend-character" d="m509.85,276.21h13.62v13.62h-13.62v-13.62Zm0,20.39h13.62v40.79h-13.62v-40.79Z"/>
                        <path class="boxfiend-character" d="m0,357.79h13.62v20.39h6.77v-20.39h13.62v20.39h20.39v34.02h-13.62v-20.39H13.62v20.39H0v-54.41Z"/>
                        <path class="boxfiend-character" d="m61.18,357.79h13.62v54.41h-13.62v-54.41Z"/>
                        <path class="boxfiend-character" d="m81.58,357.79h54.41v54.41h-13.62v-40.79h-6.77v40.79h-13.62v-40.79h-6.77v40.79h-13.62v-54.41Z"/>
                        <path class="boxfiend-character" d="m142.76,357.79h54.41v54.41h-13.62v-40.79h-27.16v40.79h-13.62v-54.41Z"/>
                        <path class="boxfiend-character" d="m203.94,357.79h54.41v54.41h-54.41v-54.41Zm40.79,40.79v-27.16h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m265.12,357.79h54.41v54.41h-40.79v6.77h-13.62v-61.18Zm40.79,40.79v-27.16h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m326.3,357.79h54.41v61.18h-13.62v-6.77h-40.79v-54.41Zm40.79,40.79v-27.16h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m387.49,357.79h54.41v13.62h-40.79v40.79h-13.62v-54.41Z"/>
                        <path class="boxfiend-character" d="m428.27,398.57h20.39v-40.79h34.02v13.62h-20.39v40.79h-34.02v-13.62Z"/>
                        <path class="boxfiend-character" d="m489.46,351.02h13.62v6.77h40.79v13.62h-40.79v27.16h40.79v13.62h-54.41v-61.18Z"/>
                        <path class="boxfiend-character" d="m0,439.36h13.62v40.79h27.16v-40.79h13.62v54.41H0v-54.41Z"/>
                        <path class="boxfiend-character" d="m61.18,439.36h13.62v40.79h27.16v-40.79h13.62v54.41h-54.41v-54.41Z"/>
                        <path class="boxfiend-character" d="m122.36,439.36h54.41v61.18h-13.62v-6.77h-40.79v-54.41Zm40.79,40.79v-27.16h-27.16v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m183.55,459.76h20.39v-6.77h-20.39v-13.62h54.41v13.62h-20.39v6.77h20.39v34.02h-13.62v-20.39h-27.16v20.39h-13.62v-34.02Z"/>
                        <path class="boxfiend-character" d="m244.73,480.15h40.79v-6.77h-40.79v-34.02h13.62v20.39h27.16v-20.39h13.62v54.41h-54.41v-13.62Z"/>
                        <path class="boxfiend-character" d="m305.91,439.36h34.02v40.79h20.39v13.62h-34.02v-40.79h-20.39v-13.62Z"/>
                    </g>
                    <g id="boxfiend-charset-numeric">
                        <path class="boxfiend-character" d="m0,552.42h54.41v54.41H0v-54.41Zm40.79,40.79v-27.16H13.62v27.16h27.16Z"/>
                        <path class="boxfiend-character" d="m61.18,593.21h20.39v-27.16h-20.39v-13.62h34.02v40.79h20.39v13.62h-54.41v-13.62Z"/>
                        <path class="boxfiend-character" d="m122.36,572.82h40.79v-6.77h-40.79v-13.62h54.41v34.02h-40.79v6.77h40.79v13.62h-54.41v-34.02Z"/>
                        <path class="boxfiend-character" d="m183.55,593.21h40.79v-6.77h-40.79v-13.62h40.79v-6.77h-40.79v-13.62h54.41v54.41h-54.41v-13.62Z"/>
                        <path class="boxfiend-character" d="m244.73,552.42h13.62v20.39h27.16v-20.39h13.62v54.41h-13.62v-20.39h-40.79v-34.02Z"/>
                        <path class="boxfiend-character" d="m305.91,593.21h40.79v-6.77h-40.79v-34.02h54.41v13.62h-40.79v6.77h40.79v34.02h-54.41v-13.62Z"/>
                        <path class="boxfiend-character" d="m367.09,552.42h54.41v13.62h-40.79v6.77h40.79v34.02h-54.41v-54.41Zm40.79,40.79v-6.77h-27.16v6.77h27.16Z"/>
                        <path class="boxfiend-character" d="m428.27,552.42h54.41v54.41h-13.62v-40.79h-40.79v-13.62Z"/>
                        <path class="boxfiend-character" d="m489.46,552.42h54.41v54.41h-54.41v-54.41Zm40.79,20.39v-6.77h-27.16v6.77h27.16Zm0,20.39v-6.77h-27.16v6.77h27.16Z"/>
                        <path class="boxfiend-character" d="m550.64,552.42h54.41v54.41h-13.62v-20.39h-40.79v-34.02Zm40.79,20.39v-6.77h-27.16v6.77h27.16Z"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}