import React from "react";


function ColorPreview({color}) {
    return (
        <div>
        <div className="transparent-texture">
            <div className="color-preview" 
                 style={{backgroundColor: `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.alpha})`}} ></div>
        </div>
        </div>
    )
}

export default ColorPreview;