import React from "react";


function ColorSwatch({color, index, selected, selectSwatch}) {
    const className = "color-swatch" + (selected ? " selected" : "");

    return (
        <div className={className} onClick={(event) => {selectSwatch(event, index)}} style={{backgroundColor: `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.alpha})`}} ></div>
    )
}

export default ColorSwatch;