import React from "react";


export default function Footer() {

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2rem",
            width: "100%",
            height: "4rem",
            backgroundColor: "#000000"
        }}>
            Blake Davis © 2024
        </div>
    )
}