import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

/* Import from wordpress */
import { getBlockTypes }      from "@wordpress/blocks";
import { registerCoreBlocks } from "@wordpress/block-library";

/* Import custom components */
import BlakesResume        from "./BlakesResume/BlakesResume.js";
import BoxFiend            from "./BoxFiend/BoxFiend.js";
import ColorOrganizer      from "./ColorOrganizer/ColorOrganizer.js";
import PixelPerfectBrushes from "./PixelPerfectBrushes/PixelPerfectBrushes.js";
import RepositoryLink      from "./RepositoryLink.js";
import ScapegoatSuite      from "./ScapegoatSuite/ScapegoatSuite.js";




export default function Post({post, type="post", width="", apiRoot}) {
    
    const containerDiv = useRef(null);
    
    const [postRoots, setPostRoots] = useState([]);
    const [postComponents, setPostComponents] = useState([]);



    useEffect(() => {
        // Get react roots from DOM
        // Convert from NodeList to Array
        // Set postRoots state
        const newPostRoots = containerDiv.current?.querySelectorAll(".react-root");
        const newPostRootsArray = Array.from(newPostRoots);
        setPostRoots(newPostRootsArray);
        
        // Add components for each root to the postComponents state array
        if (newPostRootsArray.length > 0) {
            newPostRootsArray.forEach((root, index) => {

                // BlakesResume
                if (root.className.includes("blakes-resume")){
                    let newPostComponents = Object.assign([], postComponents);
                    newPostComponents.push(<BlakesResume />);
                    setPostComponents(newPostComponents);
                    
                // BoxFiend
                } else if (root.className.includes("boxfiend")) {
                    let newPostComponents = Object.assign([], postComponents);
                    newPostComponents.push(<BoxFiend key={index} />);
                    setPostComponents(newPostComponents);

                // ColorOrganizer
                } else if (root.className.includes("color-organizer")) {
                    let newPostComponents = Object.assign([], postComponents);
                    newPostComponents.push(<ColorOrganizer key={index} />);
                    setPostComponents(newPostComponents);

                // PixelPerfectBrushes
                } else if (root.className.includes("pixel-perfect-brushes")) {
                    let newPostComponents = Object.assign([], postComponents);
                    newPostComponents.push(<PixelPerfectBrushes key={index} />);
                    setPostComponents(newPostComponents);

                // RepositoryLink
                } else if (root.className.includes("repository-link")) {
                    let url = root.dataset.url;
                    let newPostComponents = Object.assign([], postComponents);
                    newPostComponents.push(<RepositoryLink url={url} />);
                    setPostComponents(newPostComponents);

                // ScapegoatSuite
                } else if (root.className.includes("scapegoat")) {
                    let newPostComponents = Object.assign([], postComponents);
                    newPostComponents.push(<ScapegoatSuite key={index} />);
                    setPostComponents(newPostComponents);
                }
            });
        }
    }, [containerDiv]);

    let title = post.title === undefined ? "" : <h2 style={{ width: "100%", textAlign: "center"}} className="post-title">{post.title.rendered}</h2>;
    let featuredImage = "";

    if (type == "post") {
        console.log(post);
        // Set title element. If the post has hide_title custom field, set to an empty string.
        if (post.custom_fields !== undefined &&
            post.custom_fields.hide_title === "1") {
            title = ""; 
        }
    
        // Set the post's featured image, if it exists.
        const featuredMediaArray = post._embedded["wp:featuredmedia"];
        if (featuredMediaArray !== undefined) { 
            featuredImage = <img style={{borderRadius: "0.25rem", maxWidth: "20rem"}} src={apiRoot + featuredMediaArray[0]?.source_url} />
        }

    }
    let contentSplitByBlock = post.content.rendered.split("wp-block-");
    let blockNames = contentSplitByBlock.map((element) => { return element.split(" ", 1)[0] || ""; })

    let stylesToAdd = [];
    let registeredBlocks = getBlockTypes();
    if (registeredBlocks.length == 0) {
        registerCoreBlocks();
        getBlockTypes();
    } else {
        registeredBlocks = registeredBlocks.map((element) => { return element.name.split("core/")[1]; });
    }
    blockNames.forEach((block) => {
        if (registeredBlocks.includes(block)) {
            stylesToAdd.push(<link rel="stylesheet" type="text/html" href={ `${apiRoot}/wp-includes/blocks/${block}/style.css` } />);
        }

    });



    return (
        <div className="post-box">
            { title }
            <div ref={containerDiv} dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
            {  
                postRoots.map((root, index) => {
                    return (root && createPortal(postComponents[index], root))
                })
            }
            { /* featuredImage */ }
            { stylesToAdd }
        </div>
    )
}