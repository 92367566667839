import React, { useState } from "react";


export default function ArrowToggle({toggled=true, onChange={}}) {
    
    const [toggleState, setToggleState] = useState(toggled);

    return (
        <>
            <style>{`
                .arrow-toggle-label {
                    position: relative;
                    display: inline-block;
                    width: 1rem;
                    height: 1rem;

                .arrow-toggle-input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .arrow-toggle-slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;   
                    width: 0.8rem;
                    height: 0.8rem;                
                }

                input:checked + .arrow-toggle-slider {
                    transform: rotate(180deg);
                }
            `}</style>
            <label className="arrow-toggle-label">
                <input className="arrow-toggle-input" type="checkbox" checked={toggleState} onChange={() => { setToggleState(!toggleState); onChange(toggleState) }} />
                <svg className="arrow-toggle-slider" viewBox="0 0 89.25 42.4">
                    <defs>
                        <style>{`
                            .arrow-toggle-icon {
                                fill: none;
                                stroke: #555555;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 0.5rem;
                            }
                        `}</style>
                    </defs>
                    <g>
                        <polyline class="arrow-toggle-icon" points="3 3 46.88 41.43 90.76 3" />
                    </g>                
                </svg>
            </label>
        </>
    );
}