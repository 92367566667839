import React from "react";

import "./Header.css";
import "./Group.css";



function Header({feedType, gridViewButtonOnClick, listViewButtonOnClick}) {

    return (
        <div className="header-box">
            <div className="group-box horizontal v-align-center no-wrap">
                <a href="/">
                    <div className="header-logo" style={{ marginTop: "0.667rem" }}></div>
                </a>
                <a href="/">
                    <h1 className="header-title" style={{ marginTop: "0.667rem" }}>Blake Davis</h1>
                </a>
                <svg id="header-front" viewBox="0 0 878.88 127.16">
                    <g>
                        <path className="header-front" d="m878.88,127.16c-12.81,0-25.39-3.37-36.48-9.78l-173.3-100.06c-19.66-11.35-41.97-17.33-64.67-17.33H0v127.16h878.88Z"/>
                    </g>
                </svg>
            </div>

                <div className="header-back group-box horizontal no-wrap h-align-space-between v-align-center" style={{ paddingLeft: "4rem", paddingRight: "2rem"}}>
                    { /*
                    <div className="group-box horizontal no-wrap h-align-leading v-align-center">
                        <a href="/projects" className="nav-item">projects</a>
                        <div className="nav-item-divider"></div>
                        <a href="/art" className="nav-item">art</a>
                        <div className="nav-item-divider"></div>
                        <a href="/me" className="nav-item">me</a>
                    </div>
                        <div className="group-box horizontal no-wrap ">
                        <div className={"feed-view-button grid-view-button" + (feedType == "grid" ? " selected" : "")} onClick={gridViewButtonOnClick} >☷</div>
                        <div className={"feed-view-button list-view-button" + (feedType == "list" ? " selected" : "")} onClick={listViewButtonOnClick} >☰</div>
                    </div> */ } 
                </div>
        </div>
    )
}

export default Header;