import React from "react";


function ColorMarker({coordinates}) {
    // console.log(`${coordinates.x - 4}px`, `${coordinates.y - 4}px`);
    return (
        // Subtract half the diameter of the marker and the window scroll values when setting it's location
        <div className="color-organizer-marker" id="color-organizer-marker" style={{left: `${coordinates.x - 4}px`, top: `${coordinates.y - 4 /*+ window.scrollY */}px`}}></div>
    );
}

export default ColorMarker;