import React, { useEffect, useState } from "react";

import Swipeable from "../Gestures/Swipeable.tsx";



function ScapegoatSuite() {
    const apiRoot = process.env.REACT_APP_API_ROOT;

    const [slides, setSlides] = useState([
            <div className="scapegoat-slide group-box vertical no-wrap h-align-center v-align-center">
                <p className="scapegoat-slide-text">All-in-one landscape company management suite</p>
                <div className="group-box horizontal no-wrap h-align-center" >
                    <img draggable="false" style={{aspectRatio: "1/1", maxWidth: "14rem", width: "20vw", margin: "1vw", border: "0.667vw solid gray", borderRadius: "5vw"}} src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-work-app-icon.png`} />
                    <img draggable="false" style={{aspectRatio: "1/1", maxWidth: "14rem", width: "20vw", margin: "1vw", border: "0.667vw solid gray", borderRadius: "5vw"}} src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-time-clock-app-icon.png`} />
                    <img draggable="false" style={{aspectRatio: "1/1", maxWidth: "14rem", width: "20vw", margin: "1vw", border: "0.667vw solid gray", borderRadius: "5vw"}} src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-maintenance-app-icon.png`} />
                    <img draggable="false" style={{aspectRatio: "1/1", maxWidth: "14rem", width: "20vw", margin: "1vw", border: "0.667vw solid gray", borderRadius: "5vw"}} src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-employee-app-icon.png`} />
                </div>
            </div>,

            <div className="scapegoat-slide group-box vertical no-wrap h-align-center">
                <p className="scapegoat-slide-text">Manage department and employee info</p>
                <div className="group-box horizontal no-wrap h-align-center">
                    <img draggable="false" className="generic-screenshot" style={{marginRight: "1vw"}} src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-employee-screenshot-001.png`} />
                    <img draggable="false" className="generic-screenshot" src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-employee-screenshot-002.png`} />
                </div>
            </div>,
            
            <div className="scapegoat-slide group-box vertical no-wrap h-align-center">
                <p className="scapegoat-slide-text">Create and manage install projects<br />and maintenance visits</p>
                <div className="group-box horizontal no-wrap h-align-center">
                    <img draggable="false" className="generic-screenshot" style={{marginRight: "1rem"}} src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-maintenance-screenshot-002.png`} />
                    <img draggable="false" className="generic-screenshot" src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-work-screenshot-002.png`} />
                </div>
            </div>,

            <div className="scapegoat-slide group-box vertical no-wrap h-align-center">
                <p className="scapegoat-slide-text">Allow employees to clock in/out<br />and view punch statistics</p>
                <div className="group-box horizontal no-wrap h-align-center">
                    <img draggable="false" className="generic-screenshot" style={{marginRight: "1rem"}} src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-time-clock-screenshot-001.png`} />
                    <img draggable="false" className="generic-screenshot" src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-time-clock-screenshot-002.png`} />
                </div>
            </div>,

            <div className="scapegoat-slide group-box vertical h-align-center">
                <p className="scapegoat-slide-text" style={{paddingLeft: "1rem", paddingRight: "1rem"}}>Document job-site progress with the media manager</p>
                    <img draggable="false" className="generic-screenshot" src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-work-screenshot-007.png`} />
            </div>,

            <div className="scapegoat-slide group-box vertical h-align-center">
                <p className="scapegoat-slide-text" style={{paddingLeft: "1rem", paddingRight: "1rem"}}>Write notes and message employees and clients</p>
                <img draggable="false" className="generic-screenshot" src={`${apiRoot}/wp-content/uploads/projects/scapegoat/project-scapegoat-work-screenshot-003.png`} />
            </div>

            ]);
    const [selectedIndex, setSelectedIndex] = useState(0);

    function selectSlide(event, index) {
        if (event !== null) {
            event.preventDefault();
        }
        console.log(`Selected slide ${index}`);
        setSelectedIndex(index);
    }

    const slideSelectors = Array.from(Array(slides.length), (_, index) => {
        const className = `slide-selector ${ index == selectedIndex ? "selected" : "" }`;
        return (
            <div className={className} onClick={(event) => {selectSlide(event, index)}}></div>
        )
    });



    return (
        <div className="group-box vertical no-wrap h-align-center" style={ { maxWidth: "100%", overflow: "hidden" } }>
            {/* ----------------------------------------------------------------
                Scapegoat Logo 
            - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
            <svg id="scapegoat-logo" viewBox="0 0 1702.4 325.43">
                <defs>
                    <style>{`
                        .scapegoat-slide-text {
                            margin-top: 0;
                            font-size: 1.2rem;
                            max-width: 100vw;
                            padding-left: 1rem;
                            padding-right: 1rem;
                        }
                        @media only screen and (max-width: 767px) {
                            .generic-screenshot {
                                max-height: 66.667vw;
                            }
                        }
                        .generic-screenshot {
                            max-height: 30rem;
                            width: auto;
                            aspect-ratio: auto;
                            user-drag: none; 
                            user-select: none;
                            -moz-user-select: none;
                            -webkit-user-drag: none;
                            -webkit-user-select: none;
                            -ms-user-select: none;
                        }

                        @media only screen and (max-width: 767px) {
                            .scapegoat-slide {
                                max-height: 66.667vw;
                            }
                        }
                        .scapegoat-slide {
                            min-height: 30rem;
                            min-width: 100%;
                            overflow: hidden;
                            width: max-content;
                            max-width: 100vw;
                        }

                        .slide-selector {
                            width: 0.667rem;
                            height: 0.667rem;
                            background-color: gray;
                            border-radius: 100%;
                            margin: 0.375rem;
                        }
                        .slide-selector.selected {
                            background-color: white;
                        }

                        #scapegoat-logo {
                            padding-bottom: 2rem;
                            padding-left: 2rem;
                            padding-right: 2rem;
                            width: max-content;
                            max-width: 100vw;
                            fill: var(--primary-text-color);
                        }
                        .scapegoat-logo-1 {
                            fill-rule: evenodd;
                        }
                        .scapegoat-logo-1, .scapegoat-logo-2 {
                            stroke-width: 0px;
                        }
                        @media (min-width: 600px) {
                            #scapegoat-logo {
                                width: 30rem;
                            }
                        }
                    `}</style>
                </defs>
                <g>
                    <g>
                        <path className="scapegoat-logo-1" d="m128.09,0C57.4,0,0,57.4,0,128.09s57.4,128.09,128.09,128.09c24.03,0,80.76.03,109.99.05,4.8,0,9.4-1.9,12.8-5.29,3.4-3.39,5.3-8,5.3-12.8v-110.05C256.19,57.4,198.79,0,128.09,0Zm74.25,144.37c0,10.8-8.75,19.54-19.55,19.54h-106.13c-10.8,0-19.55-8.75-19.55-19.54v-21.49c0-10.8,8.75-19.55,19.55-19.55h106.13c10.8,0,19.55,8.75,19.55,19.55v21.49Z"/>
                        <g>
                            <path className="scapegoat-logo-2" d="m341.1,162.73c8.91,18.24,25.9,30.77,48.59,30.77,18.1,0,32.44-8.77,32.44-21.03s-14.06-19.21-36.62-26.32c-36.34-11.42-66-22.56-66-59.04s34.95-54.3,66.97-54.3c34.25,0,57.92,20.05,69.9,41.07l-28.96,20.61c-8.21-13.79-21.86-27.71-41.91-27.71-14.62,0-27.57,7.8-27.57,20.19,0,15.18,13.78,17.41,37.45,25.76,35.51,12.53,65.02,22.14,65.02,59.45s-37.45,55.28-71.15,55.28c-36.48,0-64.61-19.08-75.88-44.7l27.71-20.05Z"/>
                            <path className="scapegoat-logo-2" d="m482.28,157.58c0-39.13,31.61-69.76,71.29-69.76,25.06,0,49.85,13.09,62.1,38.15l-27.15,17.27c-7.8-15.46-21.44-22.56-34.39-22.56-21.44,0-36.34,16.43-36.34,37.59s15.32,36.9,35.78,36.9c14.48,0,26.59-6.82,35.64-23.11l25.76,18.1c-12.25,24.37-37.18,37.32-62.24,37.32-40.1,0-70.45-30.91-70.45-69.9Z"/>
                            <path className="scapegoat-logo-2" d="m634.74,186.4c0-25.2,23.11-42.75,58.62-42.75,9.19,0,19.49,1.53,28.13,4.59v-3.06c0-14.76-11.97-25.48-28.96-25.48-15.46,0-26.59,7.52-35.37,16.85l-20.47-20.47c9.05-11.7,28.82-27.85,56.95-27.85,39.13,0,63.49,20.75,63.49,54.16v82.29h-33.97v-14.9c-7.24,10.86-21.02,17.68-37.87,17.68-30.35,0-50.54-16.29-50.54-41.08Zm33.83-1.95c0,10.03,8.63,16.43,22.28,16.43,17.13,0,30.63-11.42,30.63-28.26-6.54-2.92-15.04-5.15-25.62-5.15-16.29,0-27.29,6.82-27.29,16.99Z"/>
                            <path className="scapegoat-logo-2" d="m795.84,280.38V91.02h34.39l.7,16.15c8.77-11.14,22.56-18.94,44-18.94,37.87,0,64.74,29.52,64.74,70.17s-26.87,69.06-64.74,69.06c-19.63,0-34.53-6.68-43.44-16.85v69.76h-35.64Zm71.85-84.93c21.16,0,36.2-16.01,36.2-37.18s-14.9-38.01-36.2-38.01-36.34,16.71-36.34,38.01,15.32,37.18,36.34,37.18Z"/>
                            <path className="scapegoat-logo-2" d="m960.83,157.72c.14-40.8,29.1-69.48,68.78-69.48,36.76,0,64.74,24.64,64.74,65.16,0,4.87-.42,9.89-1.25,15.18h-96.63c2.65,15.73,18.66,26.73,35.64,26.73,13.23,0,24.64-6.13,33.28-18.8l25.34,18.94c-12.53,20.89-35.92,32.16-59.45,32.16-42.88,0-70.45-30.91-70.45-69.9Zm100.25-13.09c-1.81-14.9-14.06-26.32-31.05-26.32s-31.05,11.14-33,26.32h64.05Z"/>
                            <path className="scapegoat-logo-2" d="m1180.96,88.24c21.44,0,35.23,7.8,44.14,18.94l.7-16.15h34.25l-.28,119.6c-.14,44.28-29.94,72.68-77.28,72.68-24.51,0-49.01-10.72-64.47-28.96l22.97-22.56c12.67,12.25,26.32,19.35,41.49,19.35,25.34,0,41.91-14.62,41.91-36.9v-4.59c-8.08,10.72-23.81,17.82-43.44,17.82-37.87,0-64.75-29.38-64.75-69.76s26.87-69.48,64.75-69.48Zm-28.82,69.34c0,21.16,14.9,37.87,36.2,37.87s36.34-16.71,36.34-37.87-15.32-37.32-36.34-37.32-36.2,15.73-36.2,37.32Z"/>
                            <path className="scapegoat-logo-2" d="m1290.12,157.86c0-39.4,30.49-69.62,71.43-69.62s71.43,30.21,71.43,69.62-30.35,69.62-71.43,69.62-71.43-30.08-71.43-69.62Zm71.43,37.32c21.02,0,35.92-15.87,35.92-37.32s-14.9-37.18-35.92-37.18-35.92,15.87-35.92,37.18,15.04,37.32,35.92,37.32Z"/>
                            <path className="scapegoat-logo-2" d="m1454.7,186.4c0-25.2,23.11-42.75,58.62-42.75,9.19,0,19.49,1.53,28.13,4.59v-3.06c0-14.76-11.97-25.48-28.96-25.48-15.46,0-26.59,7.52-35.37,16.85l-20.47-20.47c9.05-11.7,28.82-27.85,56.95-27.85,39.12,0,63.49,20.75,63.49,54.16v82.29h-33.97v-14.9c-7.24,10.86-21.03,17.68-37.87,17.68-30.35,0-50.54-16.29-50.54-41.08Zm33.83-1.95c0,10.03,8.63,16.43,22.28,16.43,17.13,0,30.63-11.42,30.63-28.26-6.54-2.92-15.04-5.15-25.62-5.15-16.29,0-27.29,6.82-27.29,16.99Z"/>
                            <path className="scapegoat-logo-2" d="m1598.39,120.68v-29.66h22.7v-42.88h34.67v42.88h40.1v29.66h-39.26v55c0,13.92,7.8,20.05,19.77,20.05,6.68,0,15.18-1.53,22.7-4.59l3.34,29.66c-8.21,4.04-21.44,6.68-32.3,6.68-29.38,0-49.15-15.18-49.15-46.64v-60.15h-22.56Z"/>
                        </g>
                        <g>
                            <path className="scapegoat-logo-2" d="m1331.75,265.6c4.19,0,7.14,2.87,7.14,7.1s-2.95,7.06-7.14,7.06-7.06-2.87-7.06-7.06,2.87-7.1,7.06-7.1Zm6.69,19.2v39.39h-12.92v-39.39h12.92Z"/>
                            <path className="scapegoat-logo-2" d="m1347.18,296.29c0-16.08,12.97-28.64,29.09-28.64s29.13,12.6,29.13,28.64-12.6,28.72-29.13,28.72-29.09-12.19-29.09-28.72Zm13.87,0c0,8.9,6.2,15.67,15.22,15.67s15.3-6.28,15.3-15.67-6.57-15.55-15.3-15.55-15.22,6.52-15.22,15.55Z"/>
                            <path className="scapegoat-logo-2" d="m1421.81,304.83c3.16,4.92,8.37,8,13.79,8,4.39,0,8-1.76,8-4.31,0-3.57-4.72-4.64-11.41-6.65-9.97-3-19.04-6.2-19.04-17.6,0-10.26,10.01-16.62,20.23-16.62s16.99,4.72,21.91,11.9l-9.97,7.34c-2.46-3.53-6.52-7.39-11.94-7.39-3.9,0-6.61,1.85-6.61,4.51,0,3.69,4.27,4.35,10.3,6.36,12.6,4.19,20.15,7.51,20.15,17.68,0,11.61-11.32,16.95-21.95,16.95-10.01,0-19.41-4.43-23.59-12.8l10.13-7.39Z"/>
                            <path className="scapegoat-logo-2" d="m1490.08,309.63c2.79,3.32,6.28,5.33,10.3,5.33,2.22,0,4.27-.62,4.27-2.42s-2.42-1.93-8.49-3.45c-7.14-1.8-12.8-5.13-12.8-12.35s6.48-12.76,16.25-12.76c8,0,13.42,4.19,16.04,7.71l-7.59,6.44c-1.48-1.8-4.43-4.14-8.58-4.14-3.04,0-4.68,1.03-4.68,2.58s2.91,2.3,7.71,3.45c7.63,1.81,14.07,5.09,14.07,12.56s-7.02,12.43-16.29,12.43-14.85-3.82-18.09-8.29l7.88-7.1Z"/>
                            <path className="scapegoat-logo-2" d="m1524.83,310.82v-26.01h12.92v22.32c0,4.39,3.49,6.48,6.61,6.48s6.56-2.09,6.56-6.48v-22.32h12.92v39.39h-11.86l-.41-3.73c-2.79,3.53-7.55,4.96-12.19,4.96-7.43,0-14.57-5.21-14.57-14.61Z"/>
                            <path className="scapegoat-logo-2" d="m1581.46,265.6c4.18,0,7.14,2.87,7.14,7.1s-2.96,7.06-7.14,7.06-7.06-2.87-7.06-7.06,2.87-7.1,7.06-7.1Zm6.69,19.2v39.39h-12.92v-39.39h12.92Z"/>
                            <path className="scapegoat-logo-2" d="m1594.87,284.8h6.28v-12.64h12.51v12.64h11.94v10.54h-11.53v12.72c0,4.27,2.42,5.58,5.83,5.58,1.89,0,4.39-.57,6.36-1.35l.9,10.5c-2.54,1.31-6.77,2.22-10.46,2.22-9.03,0-15.55-4.35-15.55-14.85v-14.81h-6.28v-10.54Z"/>
                            <path className="scapegoat-logo-2" d="m1649.81,283.98c9.68,0,19.7,6.07,19.7,19.24,0,1.44-.12,3-.37,4.6h-26.59c.53,4.27,4.64,6.28,8.7,6.28,3.53,0,6.69-2.21,8.82-4.88l8.21,7.22c-3,4.76-9.19,8.58-17.19,8.58-12.51,0-21.17-8.54-21.17-20.52s8.04-20.52,19.9-20.52Zm-7.26,16.25h15.02c-.12-3.08-3.24-6.07-7.38-6.07-4.51,0-7.18,2.83-7.63,6.07Z"/>
                        </g>
                    </g>
                </g>
            </svg>

                <Swipeable width="100vw"
                           onSwipeRight={() => { selectSlide(null, (selectedIndex > 0) ? selectedIndex-1 : slides.length-1) }}
                           onSwipeLeft={ () => { selectSlide(null, (slides.length-1 > selectedIndex) ? selectedIndex+1 : 0) }}>
                    {slides[selectedIndex]}
                </Swipeable>
            <div className="group-box horizontal no-wrap">
                {slideSelectors}
            </div>
        </div>
    )
}

export default ScapegoatSuite;
