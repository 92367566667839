import { forwardRef } from "react";
import ColorMarker from "./ColorMarker";


const ColorPicker = forwardRef(function(props, ref) {

    return (
        <div className="group-box horizontal no-wrap">
            <div className="transparent-texture">
                <div className="color-organizer-picker" style={{background: `-moz-linear-gradient(to top, rgba(0, 0, 0, ${props.currentColor.alpha}), transparent), linear-gradient(to left, hsla(${props.currentColor.hue}, 100%, 50%, ${props.currentColor.alpha}) , rgba(255, 255, 255, ${props.currentColor.alpha}))`}} ref={ref} onMouseMove={props.onMove} onClick={props.onClick} >
                    <ColorMarker coordinates={props.markerCoordinates} />
                </div>
            </div>
            <div className="color-organizer-hue-selector"></div>
        </div>
    )
});

export default ColorPicker;