import React from "react";


function ColorPropertySlider({label, name, value, min, max, step, onChange, isHidden=false,}) {
    const sliderID = "color-organizer-" + label + "-slider";
    const valueID = "color-organizer-" + label + "-value";
    
    // Format value to always have 2 decimal places
    let valueSplit = (Math.round(value * 100)/100).toString().split(".");
    valueSplit[0] = valueSplit[0].padStart(2, "0");
    
    let valueHasDecimal = valueSplit.length == 2;
    if (valueHasDecimal) { 
        valueSplit[1] = valueSplit[1].padEnd(2, "0");
    } else { 
        valueSplit.push("00")
    };
    
    const valueText = valueSplit.join(".");
  
    if (!isHidden) {
        return (
            <div className="group-box vertical">
                <div className="group-box horizontal h-align-space-between">
                    <p className="color-organizer-text">{label}</p>
                    <p className="color-organizer-text" id={valueID}>{valueText}</p>
                </div>
                <input className="color-organizer-slider" type="range" min={min} max={max} step={step} value={value} id={sliderID} name={name} onChange={onChange} />
            </div>
        );
    }
    return ( <></> );
}

export default ColorPropertySlider;